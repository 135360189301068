<template>
  <v-main>
    <v-container>
      <article-detail :article="article()"></article-detail>
    </v-container>
  </v-main>
</template>

<script>
import article from '@/mixins/article'
import { truncate } from '../utils/string'
export default {
  mixins: [article],
  data() {
    return {
      title: 'Article',
      description: ''
    }
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.description
        }
      ],
      title: this.title
    }
  },
  components: {
    ArticleDetail: () => import('@/components/Water/ArticleDetail')
  },
  methods: {
    article() {
      let res = null
      this.nitrateArticles.forEach(e => {
        if (e.id == this.$route.params.id) {
          res = e
          if (e.title) {
            this.title = e.title
          }
          if (e.abstract) {
            this.description = truncate(e.abstract || '', 150, true) + '...'
          }
        }
      })
      if (!res) {
        this.$router.push('/')
      } else {
        return res
      }
    }
  }
}
</script>

<style></style>
